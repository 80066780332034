import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getFullURL, getCurrentURL } from "@/domain/Utils";
import sun from "@assets/sol.png";
import { Helmet } from "react-helmet";

export default function Sun() {
  const previous = new LinkData("Sistema solar", "/");
  const next = new LinkData("Mercurio", "/mercurio");
  return (
    <>
      <Helmet>
        <title>Sol</title>
        <meta name="description" content="El sol es una esfera gigante
         de gas caliente, principalmente hidrógeno y helio, que emite luz y
         calor debido a las reacciones nucleares en su núcleo..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sol" />
        <meta property="og:description" content="El sol es una esfera gigante
         de gas caliente, principalmente hidrógeno y helio, que emite luz y
         calor debido a las reacciones nucleares en su núcleo..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, sun)} />;
      </Helmet>
      <Card
        symbol="&#9737;"
        title="Sol"
        footer="Créditos de la imagen: Wikipedia"
        image={sun}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">1.392.684 km</FactItem>
          <FactItem title="Gravedad">
            274 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            5778K (5505 &#8451;)
          </FactItem>
        </FactList>
        }
      >
        El sol es una esfera gigante de gas caliente, principalmente hidrógeno y helio,
        que emite luz y calor debido a las reacciones nucleares en su núcleo.
        Estas reacciones convierten el hidrógeno en helio a través de un proceso
        llamado fusión nuclear, liberando una gran cantidad de energía en el proceso.
        La luz y el calor que emite el sol son fundamentales para mantener la vida
        en la Tierra y son la fuente primaria de energía para nuestro planeta.
      </Card>
    </>
  );
}
