import './App.css';
import background from '@assets/espacio-profundo-background.webp';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${background})` }}>
      <section className="main">
        <Outlet />
      </section>
    </div>
  );
}

export default App;
