import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import neptune from "@assets/neptuno.webp";
import { Helmet } from "react-helmet";

export default function Neptune() {
  const previous = new LinkData("Urano", "/urano");
  const next = new LinkData("Sistema solar", "/");
  return (
    <>
      <Helmet>
        <title>Neptuno</title>
        <meta name="description" content="Neptuno es el octavo y último planeta
          en orden de distancia al Sol en nuestro sistema solar..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Neptuno" />
        <meta property="og:description" content="Neptuno es el octavo y último
          planeta en orden de distancia al Sol en nuestro sistema solar..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, neptune)} />;
      </Helmet>
      <Card
        symbol="♆"
        title="Neptuno"
        footer="Créditos de la imagen: Daniel Roberts de Pixabay"
        image={neptune}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">49.572 km</FactItem>
          <FactItem title="Gravedad">
            11,15 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Mín.: -223 °C; Máx.: -217,15 °C
          </FactItem>
          <FactItem title="Satélites">
            14
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            164 a 288 d
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            16,10 h
          </FactItem>
        </FactList>
        }
      >
        <p>
          Neptuno es el octavo y último planeta en orden de distancia al Sol en
          nuestro sistema solar.
        </p>
        <p>
          Es un gigante gaseoso y es un poco más pequeño que Urano, pero aún
          más grande que la Tierra. Su atmósfera está compuesta
          principalmente de hidrógeno y helio, con trazas de metano y otros
          compuestos.
        </p>
        <p>
          Es conocido por tener vientos muy fuertes y tormentas poderosas en su
          atmósfera. El viento más rápido registrado en el sistema solar se
          encuentra en Neptuno, con velocidades que superan los 2,100
          kilómetros por hora (1,300 millas por hora). Una característica
          notable es la Gran Mancha Oscura, una tormenta similar a la Gran
          Mancha Roja de Júpiter.
        </p>
      </Card>
    </>
  );
}
