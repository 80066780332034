import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import saturn from "@assets/saturn.webp";
import { Helmet } from "react-helmet";

export default function Saturn() {
  const previous = new LinkData("Júpiter", "/jupiter");
  const next = new LinkData("Urano", "/urano");
  return (
    <>
      <Helmet>
        <title>Saturno</title>
        <meta name="description" content="Saturno es el sexto planeta en orden
          de distancia al Sol en nuestro sistema solar y es conocido por sus
          impresionantes anillos..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Saturno" />
        <meta property="og:description" content="Saturno es el sexto planeta en
          orden de distancia al Sol en nuestro sistema solar y es conocido por
          sus impresionantes anillos..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, saturn)} />;
      </Helmet>
      <Card
        symbol="♄"
        title="Saturno"
        footer="Créditos de la imagen: Wikipedia"
        image={saturn}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">120.536 km</FactItem>
          <FactItem title="Gravedad">
            10,44 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Mín.: -191,15 °C; Máx.: -73 °C
          </FactItem>
          <FactItem title="Satélites">
            146
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            29 a 167 d
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            10,55 h
          </FactItem>
        </FactList>
        }
      >
        <p>
          Saturno es el sexto planeta en orden de distancia al Sol en nuestro
          sistema solar y es conocido por sus impresionantes anillos.
        </p>
        <p>
          Es famoso por sus deslumbrantes anillos, que son sistemas de anillos
          concéntricos compuestos principalmente de partículas de hielo y roca.
          Estos anillos son visibles desde la Tierra con telescopios y han sido
          objeto de fascinación desde la antigüedad. Los anillos de Saturno son
          muy extensos y están compuestos por miles de anillos individuales.
        </p>
        <p>
          Es el segundo planeta más grande en nuestro sistema solar, después de
          Júpiter. Tiene un diámetro que es alrededor de nueve veces el de la
          Tierra. Al igual que Júpiter, Saturno es un gigante gaseoso compuesto
          principalmente de hidrógeno y helio.
        </p>
      </Card>
    </>
  );
}
