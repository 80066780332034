import SpaceImage from "@/components/molecules/SpaceImage/SpaceImage";
import "./Card.css";
import FactList from "@/components/molecules/FactList/FactList";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import LinkData from "@/domain/LinkData";

type FactListType = typeof FactList;

type CardProps = {
  symbol: string,
  title: string,
  facts: ReactElement<FactListType>,
  footer: string,
  image: string,
  next: LinkData,
  previous: LinkData,
  children: React.ReactNode
};

export default function Card({ symbol, title, facts, footer, image, next, previous, children }: CardProps) {
  return (
    <section className="Card">
      <header className="Card__Header">{symbol}</header>
      <main className="Card__Info">
        <section className="Card__Description">
          {children}
        </section>
        <section className="Card__Image">
          <SpaceImage image={image}>{title}</SpaceImage>
        </section>
        <section className="Card__Facts">{facts}</section>
      </main>
      <footer className="Card__Footer">{footer}</footer>
      <nav className="Card__Nav">
        <Link className="Card__NavPrev" to={previous.href}>{previous.title}</Link>
        <Link className="Card__NavNext" to={next.href}>{next.title}</Link>
      </nav>
    </section>
  );
}
