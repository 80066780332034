import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import solarSystem from "@assets/sistema-solar.png";
import { Helmet } from "react-helmet";
import { getFullURL, getCurrentURL } from "@/domain/Utils";

export default function SolarSystem() {
  const previous = new LinkData("Neptuno", "/neptuno");
  const next = new LinkData("Sol", "/sol");
  return (
    <>
      <Helmet>
        <title>El sistema solar</title>
        <meta name="description" content="El sistema solar es un sistema
          planetario que se compone de una estrella central, el Sol, y
          todos los objetos astronómicos que están gravitacionalmente
          vinculados a él..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Sistema Solar" />
        <meta property="og:description" content="El sistema solar es un
          sistema planetario que se compone de una estrella central, el Sol, y
          todos los objetos astronómicos que están gravitacionalmente
          vinculados a él..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, solarSystem)} />;
      </Helmet>
      <Card
        symbol="🌌"
        title="Sistema Solar"
        footer="Créditos de la imagen: 3dbay.io"
        image={solarSystem}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Velocidad orbital">
            220 <sup>km</sup>/<sub>s</sub>
          </FactItem>
          <FactItem title="Planetas enanos">5</FactItem>
          <FactItem title="Satélites">400</FactItem>
          <FactItem title="Planetas menores">587.479</FactItem>
          <FactItem title="Cometas">3.153</FactItem>
        </FactList>
        }
      >
        <p>
          El sistema solar es un sistema planetario que se compone de una estrella
          central, el Sol, y todos los objetos astronómicos que están
          gravitacionalmente vinculados a él. Estos objetos incluyen planetas,
          lunas, asteroides, cometas, polvo y gas. Nuestro sistema solar se formó
          hace aproximadamente 4.6 mil millones de años a partir de una nube de gas
          y polvo interestelar en colapso.
        </p>
        <p>
          Los planetas principales en el sistema solar, ordenados desde el Sol hacia
          afuera, son Mercurio, Venus, Tierra, Marte, Júpiter, Saturno, Urano y
          Neptuno. La Tierra es el tercer planeta desde el Sol y es el único
          conocido por albergar vida.
        </p>
      </Card>
    </>
  );
}
