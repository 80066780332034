import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import earth from "@assets/tierra.webp";
import { Helmet } from "react-helmet";

export default function Earth() {
  const previous = new LinkData("Venus", "/venus");
  const next = new LinkData("Marte", "/marte");
  return (
    <>
      <Helmet>
        <title>Tierra</title>
        <meta name="description" content="La Tierra es el tercer planeta en
          orden de distancia al Sol en nuestro sistema solar y es el único
          conocido por albergar vida..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Tierra" />
        <meta property="og:description" content="La Tierra es el tercer planeta
          en orden de distancia al Sol en nuestro sistema solar y es el único
          conocido por albergar vida..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, earth)} />;
      </Helmet>
      <Card
        symbol="♁"
        title="Tierra"
        footer="Créditos de la imagen: https://snl.no/Venus"
        image={earth}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">12.756 km</FactItem>
          <FactItem title="Gravedad">
            9,80 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Mín.:	-89,15 °C; Máx.: 56,7 °C
          </FactItem>
          <FactItem title="Satélites">
            1
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            365.25d
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            1d
          </FactItem>
        </FactList>
        }
      >
        <p>
          La Tierra es el tercer planeta en orden de distancia al Sol en
          nuestro sistema solar y es el único conocido por albergar vida.
        </p>
        <p>
          Es un planeta rocoso, también conocido como un "planeta terrestre".
          Tiene un diámetro de aproximadamente 12,742 kilómetros y está
          compuesta principalmente de roca y metal. Su estructura incluye una
          corteza sólida, un manto semilíquido y un núcleo metálico.
        </p>
        <p>
          Tiene una atmósfera que sustenta la vida. Está compuesta
          principalmente de nitrógeno (alrededor del 78%) y oxígeno (alrededor
          del 21%), con trazas de otros gases como argón, dióxido de carbono y
          vapor de agua. La atmósfera desempeña un papel crucial al retener el
          calor del Sol y proporcionar el oxígeno necesario para la
          respiración.
        </p>
      </Card>
    </>
  );
}
