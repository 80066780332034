import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import mercury from "@assets/mercurio.webp";
import { Helmet } from "react-helmet";

export default function Mercury() {
  const previous = new LinkData("Sol", "/sol");
  const next = new LinkData("Venus", "/venus");
  return (
    <>
      <Helmet>
        <title>Mercurio</title>
        <meta name="description" content="Mercurio es el planeta más cercano al
          Sol en nuestro sistema solar y es el más pequeño de los planetas
          rocosos. Es un mundo rocoso..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Mercurio" />
        <meta property="og:description" content="Mercurio es el planeta más
          cercano al Sol en nuestro sistema solar y es el más pequeño de los
          planetas rocosos. Es un mundo rocoso..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, mercury)} />;
      </Helmet>
      <Card
        symbol="☿"
        title="Mercurio"
        footer="Créditos de la imagen: Wikipedia"
        image={mercury}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">4879 km</FactItem>
          <FactItem title="Gravedad">
            3,70 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Día: 350ºC; Noche: -170ºC
          </FactItem>
          <FactItem title="Satélites">
            0
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            87d 23.23h
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            58.7d
          </FactItem>
        </FactList>
        }
      >
        <p>
          Mercurio es el planeta más cercano al Sol en nuestro sistema solar
          y es el más pequeño de los planetas rocosos. Es un mundo rocoso y
          sin atmósfera significativa, lo que significa que no retiene una
          capa gruesa de gases alrededor de él.
        </p>
        <p>
          Es aproximadamente un tercio del tamaño de la Tierra y tiene una
          composición rocosa similar. Su superficie está cubierta por
          cráteres, montañas y llanuras.
        </p>
      </Card>
    </>
  );
}
