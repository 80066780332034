import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import uranus from "@assets/urano.webp";
import { Helmet } from "react-helmet";

export default function Uranus() {
  const previous = new LinkData("Saturno", "/saturno");
  const next = new LinkData("Neptuno", "/neptuno");
  return (
    <>
      <Helmet>
        <title>Urano</title>
        <meta name="description" content="Urano es el séptimo planeta en orden
          de distancia al Sol en nuestro sistema solar..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Urano" />
        <meta property="og:description" content="Urano es el séptimo planeta en
          orden de distancia al Sol en nuestro sistema solar..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, uranus)} />;
      </Helmet>
      <Card
        symbol="♅"
        title="Urano"
        footer="Créditos de la imagen: Wikipedia"
        image={uranus}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">51.118 km</FactItem>
          <FactItem title="Gravedad">
            8,69 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Mín.: -214,2 °C; Máx.: N/A
          </FactItem>
          <FactItem title="Satélites">
            27
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            369,66 d
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            17,24 h
          </FactItem>
        </FactList>
        }
      >
        <p>
          Urano es el séptimo planeta en orden de distancia al Sol en nuestro
          sistema solar.
        </p>
        <p>
          Es un gigante gaseoso, similar a Júpiter y Saturno, pero es único en
          varios aspectos. Tiene un diámetro que es aproximadamente cuatro
          veces el de la Tierra. Su atmósfera está compuesta principalmente de
          hidrógeno y helio, con trazas de metano y otros compuestos.
        </p>
        <p>
          Es conocido por tener una inclinación extrema de su eje de rotación.
          Mientras que la mayoría de los planetas en el sistema solar tienen
          ejes de rotación que están relativamente alineados con su plano
          orbital, Urano tiene un eje de rotación inclinado casi 90 grados en
          relación con su órbita. Esto significa que Urano rota de lado en
          comparación con otros planetas.
        </p>
      </Card>
    </>
  );
}
