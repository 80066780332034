import App from "@/App";
import Earth from "@/views/Earth/Earth";
import Jupiter from "@/views/Jupiter/Jupiter";
import Mars from "@/views/Mars/Mars";
import Mercury from "@/views/Mercury/Mercury";
import Neptune from "@/views/Neptune/Neptune";
import Saturn from "@/views/Saturn/Saturn";
import SolarSystem from "@/views/SolarSystem/SolarSystem";
import Sun from "@/views/Sun/Sun";
import Uranus from "@/views/Uranus/Uranus";
import Venus from "@/views/Venus/Venus";
import { RouteObject, createBrowserRouter } from "react-router-dom";

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <SolarSystem />,
      },
      {
        path: "/sol",
        element: <Sun />,
      },
      {
        path: "/mercurio",
        element: <Mercury />,
      },
      {
        path: "/venus",
        element: <Venus />,
      },
      {
        path: "/tierra",
        element: <Earth />,
      },
      {
        path: "/marte",
        element: <Mars />,
      },
      {
        path: "/jupiter",
        element: <Jupiter />,
      },
      {
        path: "/saturno",
        element: <Saturn />,
      },
      {
        path: "/urano",
        element: <Uranus />,
      },
      {
        path: "/neptuno",
        element: <Neptune />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
