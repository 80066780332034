import { ReactNode } from "react";
import "./FactItem.css";

type FactItemProps = {
  title: string;
  children: ReactNode;
};

export default function FactItem({ title, children }: FactItemProps) {
  return (
    <>
      <dt className="FactItem__Title"><h2>{title}</h2></dt>
      <dd className="FactItem__Description">{children}</dd>
    </>
  );
}
