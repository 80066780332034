import FactItem from '@/components/molecules/FactItem/FactItem';
import { ReactElement } from 'react';

type FactItemType = typeof FactItem;

type FactListProps = {
  children: ReactElement<FactItemType> | ReactElement<FactItemType>[];
};

export default function FactList({ children }: FactListProps) {
  return (
    <dl className="FactList">
      {children}
    </dl>
  );
}
