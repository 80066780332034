import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import mars from "@assets/mars.webp";
import { Helmet } from "react-helmet";

export default function Mars() {
  const previous = new LinkData("Tierra", "/tierra");
  const next = new LinkData("Júpiter", "/jupiter");
  return (
    <>
      <Helmet>
        <title>Marte</title>
        <meta name="description" content="Marte es el cuarto planeta en orden
          de distancia al Sol en nuestro sistema solar y es conocido como 'el
          Planeta Rojo' debido a su apariencia distintiva..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Marte" />
        <meta property="og:description" content="Marte es el cuarto planeta en
          orden de distancia al Sol en nuestro sistema solar y es conocido como
          'el Planeta Rojo' debido a su apariencia distintiva..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, mars)} />;
      </Helmet>
      <Card
        symbol="♂"
        title="Marte"
        footer="Créditos de la imagen: Wikipedia"
        image={mars}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">6794 km</FactItem>
          <FactItem title="Gravedad">
            3,72 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Mín: -87 °C; Máx.: 20 °C
          </FactItem>
          <FactItem title="Satélites">
            2
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            687d
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            24,6h
          </FactItem>
        </FactList>
        }
      >
        <p>
          Marte es el cuarto planeta en orden de distancia al Sol en nuestro
          sistema solar y es conocido como el "Planeta Rojo" debido a su
          apariencia distintiva.
        </p>
        <p>
          Marte es aproximadamente la mitad del tamaño de la Tierra y tiene
          una composición rocosa. Su superficie está cubierta por polvo y
          rocas, y su color característico se debe a la presencia de óxido de
          hierro, o lo que conocemos comúnmente como óxido, que le da un tono
          rojizo.
        </p>
      </Card>
    </>
  );
}
