import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import venus from "@assets/venus.webp";
import { Helmet } from "react-helmet";

export default function Venus() {
  const previous = new LinkData("Mercurio", "/mercurio");
  const next = new LinkData("Tierra", "/tierra");
  return (
    <>
      <Helmet>
        <title>Venus</title>
        <meta name="description" content="Venus es el segundo planeta más
          cercano al Sol en nuestro sistema solar y es similar en tamaño y..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Venus" />
        <meta property="og:description" content="Venus es el segundo planeta
          más cercano al Sol en nuestro sistema solar y es similar en tamaño
          y..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, venus)} />;
      </Helmet>
      <Card
        symbol="♀"
        title="Venus"
        footer="Créditos de la imagen: Wikipedia"
        image={venus}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">12.103 km</FactItem>
          <FactItem title="Gravedad">
            8,87 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Mín.: -45,15 °C; Máx.: 499,85 °C
          </FactItem>
          <FactItem title="Satélites">
            0
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            224d
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            247d
          </FactItem>
        </FactList>
        }
      >
        <p>
          Venus es el segundo planeta más cercano al Sol en nuestro sistema solar
          y es similar en tamaño y composición a la Tierra. A menudo se le conoce
          como el "gemelo de la Tierra" debido a estas similitudes, pero a pesar
          de ello, Venus tiene condiciones muy diferentes y extremas.
        </p>
        <p>
          Venus tiene una atmósfera densa y compuesta principalmente de dióxido
          de carbono con nubes espesas de ácido sulfúrico. Esta atmósfera atrapa
          el calor, creando un fuerte efecto invernadero que hace que la
          temperatura en la superficie sea extremadamente alta. De hecho, Venus
          tiene la atmósfera más caliente de todos los planetas del sistema solar.
        </p>
      </Card>
    </>
  );
}
