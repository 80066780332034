import FactItem from "@/components/molecules/FactItem/FactItem";
import FactList from "@/components/molecules/FactList/FactList";
import Card from "@/components/organisms/Card/Card";
import LinkData from "@/domain/LinkData";
import { getCurrentURL, getFullURL } from "@/domain/Utils";
import jupiter from "@assets/jupiter.webp";
import { Helmet } from "react-helmet";

export default function Jupiter() {
  const previous = new LinkData("Marte", "/marte");
  const next = new LinkData("Saturno", "/saturno");
  return (
    <>
      <Helmet>
        <title>Júpiter</title>
        <meta name="description" content="Júpiter es el quinto planeta en orden
          de distancia al Sol en nuestro sistema solar y es el planeta más
          grande..."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Júpiter" />
        <meta property="og:description" content="Júpiter es el quinto planeta
          en orden de distancia al Sol en nuestro sistema solar y es el planeta
          más grande..."
        />
        <meta property="og:url" content={getCurrentURL(window)} />
        <meta property="og:image" content={getFullURL(window, jupiter)} />;
      </Helmet>
      <Card
        symbol="♃"
        title="Júpiter"
        footer="Créditos de la imagen: Wikipedia"
        image={jupiter}
        next={next}
        previous={previous}
        facts={<FactList>
          <FactItem title="Diámetro">142.984 km</FactItem>
          <FactItem title="Gravedad">
            24,79 <sup>m</sup>/<sub>s²</sub>
          </FactItem>
          <FactItem title="Temperatura">
            Mín.: -163,15 °C; Máx.: -75,15 °C
          </FactItem>
          <FactItem title="Satélites">
            95
          </FactItem>
          <FactItem title="Periodo orbital (año)">
            11 a 315 d
          </FactItem>
          <FactItem title="Periodo rotación (día)">
            9,92h
          </FactItem>
        </FactList>
        }
      >
        <p>
          Júpiter es el quinto planeta en orden de distancia al Sol en nuestro
          sistema solar y es el planeta más grande.
        </p>
        <p>
          Es un gigante gaseoso y tiene un diámetro que es más de 11 veces el
          de la Tierra. Su masa es aproximadamente 318 veces la de la Tierra.
          Aunque se compone principalmente de hidrógeno y helio, no tiene una
          superficie sólida definida, sino que su atmósfera se vuelve
          gradualmente más densa hacia el centro del planeta.
        </p>
        <p>
          Tiene una atmósfera espesa y dinámica que incluye bandas de nubes y
          una Gran Mancha Roja, una gigantesca tormenta que ha estado activa
          durante siglos. Las bandas de nubes son causadas por patrones de
          circulación atmosférica en la atmósfera superior.
        </p>
      </Card>
    </>
  );
}
