import React from "react";
import "./SpaceImage.css";

type SpaceImageProps = {
  image: string;
  children: React.ReactNode;
};

export default function SpaceImage({ image, children }: SpaceImageProps) {
  return (
    <div className="SpaceImage" style={{ backgroundImage: `url(${image})` }}>
      <h1 className="SpaceImage__Title">{children}</h1>
    </div>
  );
}
